import type {
  WCartView,
  WRegistryCollectionItemView,
  WProductView,
} from '@zola/svc-web-api-ts-client';
import { useAppDispatch } from '../store';
import { trackProductAdded } from '../util/trackingHelper';
import { showModal } from '../actions/ModalActions';
import {
  setSelectedRegistryItem,
  deleteRegistryItem,
  updateCollectionOrder,
} from '../actions/ManageRegistryActions';
import { addItemToCart } from '../actions/CartActions';

const OPEN_MODAL_EVENT = 'OPEN_MODAL_EVENT';
const GIFT_CARD_TYPE = 'GIFT_CARD';

/**
 * Creates payload object for custom event from product and supplemental data
 * @param {Object} product - registry collection item
 * @param {Number} quantity - amount of a product
 * @param {String} cartId - cart id to which product is being added to.
 * @returns {Object} - complete payload for custom event tracking
 */
export const buildCustomEventData = (
  product: WRegistryCollectionItemView,
  cartId?: string,
  registryId?: string,
  quantity = 1
) => {
  const sku = product.sku_object_id;
  const productId = product.product_id;
  const productLookId = product.product_look_id;
  const { name, price } = product;
  const brand = product.brand && product.brand.name;
  const imageUrl = product.images?.[0].medium;

  return {
    cart_id: cartId,
    registry_id: registryId,
    product_id: productId,
    sku,
    name,
    brand,
    price,
    quantity,
    image_url: imageUrl,
    variant: productLookId,
    location: 'PUBLIC_REGISTRY',
    section: 'QUICK_ADD',
  };
};

export const useRegistryActions = (registryKey: string) => {
  const dispatch = useAppDispatch();

  const handleItemRearrange = (currentPosition: number, targetPosition: number) => {
    dispatch(updateCollectionOrder(currentPosition, targetPosition));
  };

  const handleItemRemove = (itemId: string) => {
    dispatch(deleteRegistryItem(itemId));
  };

  const handleEditItemClick = (item: WRegistryCollectionItemView) => {
    dispatch(setSelectedRegistryItem(item));
  };

  const handleProductClick = (item: WRegistryCollectionItemView | WProductView) => {
    const isDefaultGiftCard = item.type === GIFT_CARD_TYPE;
    const collectionItemId = 'object_id' in item ? item.object_id : '';
    const currentUrl = `${window.location.pathname}${window.location.search}`;
    const newUrl = isDefaultGiftCard
      ? `/registry/${registryKey}/zola-gift-card`
      : `/registry/collection-item/${collectionItemId}`;

    dispatch(
      showModal(
        'PRODUCT',
        {
          collectionItemId,
          registryKey,
          collectionItem: item,
          registryOriginUrl: currentUrl,
        },
        {
          size: 'xl',
          onClose: () => window.history.pushState(null, '', currentUrl),
        }
      )
    );

    // Enable Changing URL without routing the user
    window.history.pushState(null, '', newUrl);

    return null;
  };

  const handleAddItemToCart = (item: WRegistryCollectionItemView, quantity = 1) => {
    const collectionItemId = item.object_id;
    const skuId = item.sku_object_id;
    const request = {
      collectionItemId,
      skuId,
      quantity,
      itemOrigin: 'WEDDING_REGISTRY',
    };

    dispatch(addItemToCart(request))
      .then((result: WCartView) => {
        const { cart_id, cart_items } = result;
        const purchasedItem = cart_items?.find(
          (cartItem) => cartItem.collection_item_id === collectionItemId
        );
        const { registry_id } = purchasedItem || {};
        const eventData = buildCustomEventData(item, cart_id, registry_id, quantity);
        trackProductAdded(eventData);
        window.dispatchEvent(new CustomEvent(OPEN_MODAL_EVENT, { detail: 'CART' }));
      })
      .catch((error: Error) => {
        window.dispatchEvent(
          new CustomEvent(`UPDATE_MAX_QUANTITY_${collectionItemId}`, { detail: error })
        );
      });
  };

  const handleItemClick = (item: WRegistryCollectionItemView | WProductView, quantity = 1) => {
    if (!('object_id' in item)) {
      return handleProductClick(item);
    }

    const isGroupGift = item.contributions?.group_gift;
    // @ts-expect-error svc-client-ts type issue
    const isExternalGift = item.type === 'EXTERNAL' && item.cash_fund === false;
    if (isGroupGift || isExternalGift) {
      handleProductClick(item);
    } else {
      // ZOLA product
      handleAddItemToCart(item, quantity);
    }

    return null;
  };

  return {
    handleEditItemClick,
    handleItemClick,
    handleProductClick,
    handleItemRearrange,
    handleItemRemove,
  };
};
